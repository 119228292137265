import React from 'react'
import Layout from '../components/layout';
import Seo from '../components/seo';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';


const TermsAndConditions = (location) => {
    const { t } = useTranslation(); 
  return (
    <Layout location={location}>
            <Seo
                title={`${t('TermsAndConditionsLabel')}`}
                meta={[
                    {
                        name: `robots`,
                        content: 'noindex',
                    }
                ]}
            />
            <div className="c-block c-block--first">
                <div className="site-width">
                    <h2 className="c-block__title">{t('TermsAndConditionsLabel')}</h2>

                    <div className="c-block__text">
                        <div className="text-content">
                            <h2>1. Aanvaarding</h2> 
                            <p>Het verkoopcontract tussen AutoHunt bv (hierna verkoper) en de koper en de daaruit voortvloeiende leveringen worden beheerst door de wet, de op de voorzijde vermelde bij­zondere bepalingen en door onderhavige algemene contractvoorwaarden, waarvan de koper erkent kennis genomen te hebben.</p>
                        </div>
                        <div className="text-content">
                            <h2>2. Levering</h2> 
                            <p>Behoudens andersluidende schriftelijke bepaling worden de verkochte voertuigen in de vestiging van de verkoper geleverd op de overeengekomen leveringsdag of -termijn. De verkoper kan, wegens aan hem vreemde oorzaak, echter de voorziene leveringsdatum met 30 dagen verlengen of de leveringstermijn met maximaal 25% verlengen indien de verkoper zulks per aangetekende brief laat weten aan de koper.
                            </p>            
                        </div>
                        <div className="text-content">
                            <h2>3. Betaling</h2> 
                            <p>Onverminderd de toepassing van de bepalingen met betrekking tot het consumentenkrediet zoals opgenomen in Boek VII, Titel 4, Hoofdstuk 1 van het Wetboek Economisch Recht, kan de Verkoper geen betaling vragen van een voorschot dat meer dan 15% van de verkoopprijs van het voertuig bedraagt. Bij wijze van afwijking, kan ofwel voor een speciale bestelling ofwel mits akkoord van de Koper, het voorschot vastgesteld worden op een ander percentage.
</p><p>De volledige prijs of het saldo wordt contant betaald op het ogenblik van de levering, tenzij uitdrukkelijk anders werd overeengekomen. Gebeurt dat niet, dan zijn op de verschuldigde som van rechtswege en zonder ingebrekestelling intresten verschuldigd ad. 1,5% per maand.
Het voertuig blijft eigendom van de verkoper tot de prijs volledig is betaald. Als de verkoper 10 dagen na het verzenden van een aangetekend schrijven nog altijd geen betaling heeft ontvangen, mag zij de verkoop ontbinden per aangetekende brief gericht aan de koper.
In dat geval is de koper aan de handelaar een vergoeding verschuldigd die overeenstemt met de opgelopen schade en die ten minste 20% bedraagt van de overeengekomen prijs met een minimum van 2.000,00 EUR</p>
<p>Facturen mogen niet overgedragen of gecompenseerd worden, behoudens voorafgaand schriftelijk akkoord van de verkoper. Indien de koper een consument is, mag de koper de bedragen die hij verschuldigd is aan de verkoper compenseren met de bedragen die de verkoper hem verschuldigd is.
</p>
<p>Overeenkomstig de bepalingen van de Wet op de Financiële Zekerheden van 15 december 2004 compenseren en verrekenen de Verkoper en de Koper automatisch en van rechtswege alle actueel bestaande en toekomstige schulden opzichtens elkaar. Dit betekent dat in de permanente relatie tussen de Verkoper en de Koper steeds maar de grootste schuldvordering per saldo na de voormelde automatische verrekening overblijft. Deze schuldvergelijking zal in elk geval tegenstelbaar zijn aan de curator en de overige samenlopende schuldeisers, die zich dus niet zullen kunnen verzetten tegen de doorgevoerde schuldvergelijking.
</p>
<p>De verkoper is ontlast van zijn verplichting tot aflevering zolang de overeengekomen prijs, in hoofdsom en met bijkomende kosten, niet volledig betaald is.
</p>
                        </div>
                        <div className="text-content">
                            <h2>4. Overmacht</h2> 
                            <p>Indien de verkoper belet zou zijn om een van de voertuigen of diensten waarvoor de overeenkomst werd afgesloten, te leveren, uit te voeren of te voltooien, omwille van eender welke gebeurtenis, om redenen onafhankelijk van haar wil, inclusief – zonder dat deze lijst beperkend is – natuurrampen, oorlog, terroristische activiteiten, sociale onlusten, pandemieën, het feit dat men geen toestemming, licenties of registraties kan bekomen, overheidsbeslissingen, ziekte, overlijden of ontslag van de werknemer die met de dienstverlening van de verkoper belast is, of het feit dat de koper zijn contractuele verplichtingen niet nakomt, ontslaat de verkoper van haar contractuele verplichtingen.
</p>            
                        </div>
                        <div className="text-content">
                            <h2>5. Garantie</h2> 
                            <p>Gegevens van de garant: AutoHunt bv ,met maatschappelijke zetel te 8770 INGELMUNSTER,  Bouwke 17 en ondernemingsnummer 0784.451.470.
</p>
<p>Voor de verkochte voertuigen, waarvoor de kopers vooraleer er bezit van te nemen alle gelegenheid kregen om ze te onderzoeken en te testen, wordt tussen de partijen uitdrukkelijk overeengekomen dat de tussenkomst van de verkoper als garant, zoals in punt 8.a. geïdentifi­ ceerd, voor wat de garantie betreft, beperkt is tot een duur van 12 maanden te rekenen vanaf de levering van het tweedehandsvoertuig.
</p>    
<p>Deze garantie is enkel van toepassing indien het gebrek zich heeft voorgedaan op het grondgebied van de Europese Unie, met inbegrip van Zwitserland en de prinsbisdommen van Andorra, Liechtenstein en Monaco en indien het voertuig zich op Belgisch grondgebied bevindt op het ogenblik waarop de koper de tussenkomst eist van de verkoper in toepassing van de garantie.
</p>
<p>De garantie geldend gedurende 12 maanden, laat de wettelijke rechten die de consument heeft krachtens de toepasselijke nationale wetgeving betreffende de verkoop van consumptie­ goederen (Wet van 01.09.2004 betreffende de bescherming van de consumenten bij verkoop van consumptiegoederen), onverlet.
</p>       
<p>De onder garantie uitgevoerde werken moeten in de werkplaats van de verkoper of in een door hem aangestelde werkplaats worden uitgevoerd. Uitsluitend na voorafgaandelijke schriftelijke toestemming van de verkoper, mag de koper de herstelling laten uitvoeren in een andere werkplaats.
</p>
<p>De koper die zich wil beroepen op de garantie moet de verkoper schriftelijk verwittigen binnen de kortst mogelijke tijd vanaf het ogenblik waarop hij de gebreken vaststelt of ze had moeten vaststellen. In ieder geval dient de koper de verkoper daarvan op de hoogte te brengen binnen de twee maanden vanaf het ogenblik waarop het gebrek werd vastgesteld. De rechtsvordering van de koper verjaart na verloop van één jaar. De huidige garantie wordt enkel afgesloten in het voordeel van de koper en is in geen geval overdraagbaar.
</p>
<p>De koper verbindt zich ertoe het nodige te doen om de schade niet te verergeren, desgeval­ lend door het voertuig niet te gebruiken. Met de verergering van de schade voortvloeiend uit het gebruik van het goed door de consument zal in ieder geval rekening worden gehouden om de tussenkomst van de verkoper te bepalen.
</p>
<p>De garantie omvat enkel de gratis herstelling van het voertuig door de verkoper of door een door de verkoper uitdrukkelijk en schriftelijk aangeduide hersteller. Voor onderdelen die tijdens dergelijke herstelling worden vervangen, geldt dezelfde garantie tot het einde van de voertuiggarantie. Vervangen onderdelen worden eigendom van de verkoper.
</p>
<p>De tussenkomst onder garantie van de verkoper hangt af van het gebruik van het voertuig als goede huisvader en van het respecteren van de gebruiksaanwijzing van de constructeur.
</p>
<p>In ieder geval zijn van de garantie uitgesloten: de onderhoudsbeurten, de afstellingen, het aanspannen en andere voorbereidingen nodig voor het normaal gebruik van het voertuig, alsook de onderdelen en organen die normaal vervangen worden bij onderhoudsbeurten.
</p>
<p>De garantie dekt evenmin de normale slijtage aan het voertuig (zoals onder meer (niet limitatief): schokdempers, banden, remmen, uitlaten, wissers, koppeling en massavliegwiel, enz.) of externe mechanische of chemische invloeden (met betrekking tot lak­ of koetswerk­ schade, o.m. steenslag, roestdeeltjes in de lucht, industriële luchtvervuiling) die inwerken op het voertuig.
De garantie kan niet door de koper worden ingeroepen indien het gebrek te wijten is aan een foutief/abnormaal gebruik van het voertuig of rijgedrag, aan nalatigheid (o.m. het rijden met gemis aan olie, water of antigel, het niet tijdig stoppen of laten herstellen na een onregel­ matigheid), of een gebrek aan onderhoud (de koper moet een regelmatig onderhoud van het voertuig kunnen voorleggen bij de verkoper of bij een door verkoper uitdrukkelijk en schriftelijk aangewezen hersteller) of aan slecht onderhoud door de koper, of wanneer het voertuig zonder de uitdrukkelijke toestemming van de verkoper buiten de werkplaatsen van deze laatste werd hersteld.
</p>
<p>In geen geval kan de verkoper aangesproken worden door gevolgschade, dervingvergoeding of dergelijke.
</p>
<p>De garantie bestaat alleen tussen de verkoper en de koper. Ze kan niet overgedragen worden aan een nieuwe koper als het voertuig doorverkocht wordt.
</p>
                        </div>

                        <div className="text-content">
                            <h2>6. Conformiteit</h2> 
                            <p>De conformiteit en zichtbare gebreken, waarvan de koper op het ogenblik van de levering redelijkerwijs kennis zou kunnen hebben genomen, worden verondersteld aanvaard te zijn door het enkele feit van de levering, behoudens indien de koper zich daartegen verzet door middel van een schriftelijk protest op de voorzijde van deze factuur/koopovereenkomst.
</p><p>Niet-essentiële kenmerken, welke niet als specifiek doorslaggevend werden overeengekomen, kunnen mogelijks verschillen van het oorspronkelijk voorstel en kunnen geen aanleiding geven tot weigering van afname van het voertuig. Betreffende de kilometerstand aanvaardt de koper dat het voertuig op het ogenblik van de levering mogelijks een hogere kilometerstand aangeeft (tot 4% boven deze op het koopcontract vermeld met een maximum van 1.000 km), aangezien zeker inzake tweedehandsvoertuigen het voertuig nog dient verplaatst voor onder meer eventueel onderhoud, technische keuring, herstellingen, testen, poetsbeurten, testritten, presentaties en dergelijke.
</p>            
                        </div>
                        <div className="text-content">
                            <h2>7. Overname van een voertuig</h2> 
                            <p>Als de bestelbon melding maakt van de overname van een voertuig toebehorend aan de koper, dan is de overname afhankelijk van de levering van het voertuig dat het voorwerp uitmaakt van deze verkoop, evenals van het bewijs dat de verkoper eigenaar is van het over te nemen voertuig, dat hij er vrij kan over beschikken en dat alle verplichtingen m.b.t. een eventuele financiering werden uitgevoerd. De overnamewaarde van het over te nemen voertuig, overeengekomen bij de bestelling van het voertuig, is definitief voor zover de staat van het voertuig toebehorende aan de koper, op het ogenblik van zijn levering door de verkoper, op kleine niet essentiële details na, overeenstemt met de beschrijving ervan op de bestelbon of in een erbij gevoegd document.
</p>            
                        </div>
                        <div className="text-content">
                            <h2>8. Toepasselijk recht en bevoegde rechtbank
</h2> 
                            <p>Alle betwistingen betreffende de uitvoering van onderhavige overeenkomst, met inbegrip van de eventuele verplichtingen van de verkoper gedurende de garantieperiode, vallen onder de uitsluitende bevoegdheid van de rechtbanken bevoegd over de zetel van de verkoper.
</p>            
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
  )
}

export default TermsAndConditions

export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
      }
`